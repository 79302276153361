const downloadStreamResponse = (res, study, prefix) => res.blob().then((blob) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${prefix}_${study.title}`;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
});

export const DownloadDicomFile = (study, keycloak) => {
  fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/dicom-web/studies/${study.studyInstanceUid}?accept=application/zip`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  })
    .then((res) => downloadStreamResponse(res, study, 'alma3d_study'))
    .catch((error) => console.warn(error));
};

export const DownloadDicomCD = (study, keycloak) => {
  fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/dicom-cd/studies/${study.studyInstanceUid}?accept=application/zip;transfer-syntax=*&dicomdir=true`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  })
    .then((res) => downloadStreamResponse(res, study, 'lite_cd_alma3d_study'))
    .catch((error) => console.warn(error));
};

export const RejectDicomStudy = async (study, keycloak) => (
  fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/dicom-web/studies/${study.studyInstanceUid}/reject/113039^DCM`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      DNT: 1,
    },
    body: {},
  }));


export default DownloadDicomFile;
