import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  subheader: {
    borderBottom: '1px solid #0002',
  },
  wrapper: {
    display: 'flex',
    minHeight: 'calc(100% - 1.5em)',
    flexDirection: 'row',
    padding: '.75em 0',
  },
  box: {
    position: 'relative',
    width: '100%',
    flexGrow: 1,
    padding: '0 .5em',
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  patientInfo: {
    fontSize: '.9em',
    marginTop: '-.25em',
    marginBottom: '.75em',
  },
  caseStatus: {
    marginTop: '-.25em',
    width: '100%',
    textAlign: 'right',
    '& .MuiChip-root': {
      fontSize: '1em',
    },
    '& .MuiChip-colorPrimary': {
      color: theme.palette.common.white,
    },
  },
  indent: {
    padding: '0 1em',
  },
  caseTitle: {
    marginBottom: '.5em',
    fontSize: '1.75em',
    color: theme.palette.primary.dark,
  },
  subtype: {
    marginBottom: '.125em',
    fontSize: '.9375em',
    '& > .value': {
      fontSize: '1.125em',
      fontWeight: 700,
    },
  },
  description: {
    margin: 'auto 0 .375em -.5em',
    padding: '.375em .75em',
    width: 'calc(100% - 1em)',
    border: '1px solid #e7e7e7',
    borderRadius: 6,
    fontSize: '.9375em',
    textAlign: 'justify',
  },
  accordionMain: {
    margin: '0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      margin: '0 -.5em',
      color: theme.palette.common.white,
      background: theme.palette.primary.dark,
    },
  },
  accordionInfo: {
    margin: '0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      margin: '0 -.5em',
      background: theme.palette.primary.main,
    },
  },
  accordionSecondary: {
    margin: '0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      margin: '0 -.5em',
    },
  },
  accordionStudies: {
    margin: '0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      margin: '0 -.5em',
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
    '& .MuiCollapse-entered': {
      margin: '.5em 0 0',
    },
  },
  userInfo: {
    position: 'absolute',
    bottom: 0,
    right: 5,
  },
  adminInfo: {
    marginTop: 'auto',
    padding: '.25em .5em 0',
    '& > .MuiGrid-container': {
      justifyContent: 'flex-end',
      alignItems: 'baseline',
    },
  },
  avatar: {
    width: '1.875em',
    height: '1.875em',
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: '1em',
    border: '1px #ddd solid',
    cursor: 'pointer',
    '@media (max-width:600px)': {
      marginTop: 0,
    },
  },
  initials: {
    width: '2.5em',
    height: '2.5em',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: '.9375em',
    cursor: 'pointer',
  },
  popover: {
    fontSize: '1.25rem',
    padding: '0.75em',
  },
  list: {
    paddingInlineStart: 30,
    paddingInlineEnd: 8,
    fontSize: '.9em',
    color: theme.palette.grey.A700,
  },
  noItems: {
    marginLeft: '.375em',
    padding: 8,
    color: theme.palette.grey.A200,
  },
}));

export default useStyles;
