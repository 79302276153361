import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/MenuLayout';
import StudyDetails from '../../components/Study/StudyDetails';
import Loading from '../../components/Common/Loading';
import { GET_STUDY } from '../../graphql/queries';
import { getUserInterface } from '../../utils/patientUserHospitalUtils';
import StudyPatientDetails from '../../components/Study/StudyPatientDetails';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';

export default ({ studyId }) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const { loading, data, error, refetch } = useQuery(
    GET_STUDY,
    {
      variables: { uuid: studyId },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const userInterface = getUserInterface();
  const StudyView = userInterface === 'patient' ? StudyPatientDetails : StudyDetails;
  const study = data && data.dicomStudy;

  if (!errorMessage && error) setErrorMessage(networkErrorParse(error));

  return (
    <Layout>
      {
        loading && !study && !errorMessage
          ? <Loading />
          : <StudyView study={study} refetch={refetch} errorMessage={errorMessage} />
      }
    </Layout>
  );
};
