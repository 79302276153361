import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import moment from 'moment-timezone';

import { Box, Grid, Paper } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';

import { getYears } from '../../utils/dateTimeUtils';
import { GreyLabel } from '../Common/styled/GreyLabel';
import { LinkTextButton } from '../Common/styled/TextButton';
import { InnerSpanHTML } from '../Common/InnerHTML';
import useStyles from './styled/StudyMakeStyles';
import { getUserInterface } from '../../utils/patientUserHospitalUtils';
import UserInfo from '../../componentsUI/UserInfo';
import { FIND_USER_PROFILE_BY_UUID } from '../../graphql/queries';

export const StudyDetailsInfoCard = ({ study }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userInterface = getUserInterface();
  const age = study.medicalCase && getYears(moment(study.medicalCase.patient.birthDate).utc().locale(i18n.language));

  const { data } = useQuery(
    FIND_USER_PROFILE_BY_UUID,
    {
      variables: {
        uuid: study.owner,
      },
    },
  );

  const admin = data && data.userByUuid;

  return (
    <Paper elevation={2} className={classes.wrapper} style={{}}>
      <Box className={classes.box}>
        <Box className={classes.userInfo}>
          <div className={classes.adminInfo}>
            <Grid container>
              {admin && <UserInfo admin={admin} entity={study} type="study" />}
            </Grid>
          </div>
        </Box>
        <Grid container justifyContent="flex-start" alignItems="stretch">
          {userInterface !== 'patient' && (
            <Grid item xs={12}>
              <div className={classes.patientInfo}>
                {study.medicalCase ? (
                  <>
                    <GreyLabel>{`${t('patient')}: `}</GreyLabel>
                    <Link to={`/patient/${study.medicalCase.patient.uuid}`}>
                      <LinkTextButton>
                        {`${study.medicalCase.patient.name} ${study.medicalCase.patient.surname}`}
                      </LinkTextButton>
                    </Link>
                    <strong>{age}</strong>
                  </>
                ) : (
                  <GreyLabel>{t('hospital.study')}</GreyLabel>
                )}
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={10} className={classes.indent}>
            <div className={classes.caseTitle}>{study.title}</div>
            <Box className={classes.description}>
              <InnerSpanHTML content={study.description} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default StudyDetailsInfoCard;
