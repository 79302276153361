import { isEmpty } from '../../../utils/ObjectUtils';
import { getUserInterface } from '../../../utils/patientUserHospitalUtils';

export const getStudyApplicationPath = (s) => {
  if (isEmpty(s)) return null;

  let patient = null;
  let medicalCase = null;

  const userInterface = getUserInterface();

  if (s.medicalCase) {
    if (userInterface !== 'patient') {
      patient = {
        name: `${s.medicalCase.patient.name} ${s.medicalCase.patient.surname}`,
        link: `/patient/${s.medicalCase.patient.uuid}`,
      };
    }
    medicalCase = {
      name: s.medicalCase.title,
      link: `/case/${s.medicalCase.uuid}`,
    };
  }

  return ({
    patient,
    medicalCase,
    study: {
      name: s.title,
      link: `/study/${s.uuid}`,
    },
  });
};
