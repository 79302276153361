import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { getFormattedDate } from '../../utils/dateTimeUtils';
import { Span } from '../Common/styled/Text';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.75em 0',
    // backgroundColor: '#303030',
    // color: '#e7e7e7',
  },
  box: {
    width: '100%',
    flexGrow: 1,
    padding: '0 .5em',
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  subheading: {
    color: theme.palette.primary.main,
    margin: '0 .5em',
    fontSize: '1.125em',
    fontWeight: 700,
  },
  attr: {
    color: '#aaa',
  },
  details: {
    marginTop: '.125em',
    fontSize: '.9375em',
    '& p': {
      margin: '.125em .375em 0',
    },
    '& p.first': {
      marginTop: '.625em',
    },
  },
}));

export const StudyDetailsDicomCard = ({ study }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const patient = study.medicalCase ? study.medicalCase.patient : null;
  const createdAt = getFormattedDate({ date: study.createdAt });
  const updatedAt = getFormattedDate({ date: study.updatedAt });
  const modalities = study.modalities.join(', ');

  return (
    <Paper elevation={2} className={classes.wrapper}>
      <Box className={classes.box}>
        <Grid container>
          <Grid item xs={12} className={classes.subheading}>
            {t('study.file.info')}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} className={classes.details}>
              <Grid item xs={12}>
                {patient && (
                  <>
                    <p className="first">
                      <Span className={classes.attr}>{`${t('patient.id')}: `}</Span>
                      {patient.customId}
                    </p>
                    <p>
                      <Span className={classes.attr}>{`${t('first.name')}: `}</Span>
                      {patient.name}
                    </p>
                    <p>
                      <Span className={classes.attr}>{`${t('last.name')}: `}</Span>
                      {patient.surname}
                    </p>
                  </>
                )}
                <p className="first">
                  <Span className={classes.attr}>{`${t('series')}: `}</Span>
                  {study.numberOfSeries}
                </p>
                <p>
                  <Span className={classes.attr}>{`${t('images')}: `}</Span>
                  {study.numberOfInstances}
                </p>
                <p>
                  <Span className={classes.attr}>{`${t('modality')}: `}</Span>
                  {modalities}
                </p>
                <p className="first">
                  <Span className={classes.attr}>{`${t('study.date')}: `}</Span>
                </p>
                <p>
                  {createdAt}
                </p>
                <p className="first">
                  <Span className={classes.attr}>{`${t('study.uploaded.on')}: `}</Span>
                </p>
                <p>
                  {updatedAt}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default StudyDetailsDicomCard;
